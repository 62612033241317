import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  // {
  //   path: '/',
  //   name: 'cooming-soon',
  //   component: () => import('../views/comingSoon.vue')
  // },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/services",
    name: "services",
    component: () => import("../views/services.vue"),
  },
  {
    path: "/service/:id",
    name: "service_detail",
    component: () => import("../views/service_detail.vue"),
  },
  {
    path: "/works",
    name: "works",
    component: () => import("../views/works.vue"),
  },
  {
    path: "/jobs",
    name: "jobs",
    component: () => import("../views/Jobs.vue"),
  },
  {
    path: "/jobs/:id",
    name: "jobDetails",
    component: () => import("../views/JobDetails.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("../views/contact.vue"),
  },
  {
    path: "/service_detail/:id",
    name: "service_detail_description",
    component: () => import("../views/service_detail_desc.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    component: () => import("../views/notFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      // If a saved position is available, use it
      return savedPosition;
    } else if (to.hash) {
      // If a hash is present in the URL, scroll to the element with that hash
      return {
        el: to.hash,
      };
    } else {
      // Default behavior: scroll to the top
      return {
        top: 0,
      };
    }
  },
});

export default router;
