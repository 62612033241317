<template>
  <!--====== TEAM STYLE ONE START ======-->
  <section class="team-area p-5">
    <div class="container">
      <h1 class="mb-5">OUR TEAMS</h1>
      <Carousel
        class="mt-3"
        v-bind="settings"
        :autoplay="2000"
        :breakpoints="breakpoints"
        :wrapAround="true"
        :transition="500"
      >
        <Slide v-for="item in teams" :key="item">
          <div class="carousel__item">
            <div class="single-team text-center team-style-one">
              <div class="team-image">
                <img :src="item.image_url" alt="Team" height="300" />
              </div>
              <div class="team-content">
                <h4 class="name">{{ item.name }}</h4>
                <span class="sub-title">{{ item.position }}</span>
              </div>
            </div>
          </div>
        </Slide>
      </Carousel>
    </div>
    <!-- container -->
  </section>
  <!--====== TEAM STYLE ONE ENDS ======-->
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import axios from "axios";

export default {
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  data() {
    return {
      teams: {},
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
      },
      breakpoints: {
        // 700px and up
        700: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        // 1024 and up
        1024: {
          itemsToShow: 3.95,
          snapAlign: "center",
        },
      },
      apiUrl: process.env.VUE_APP_API_URL,
    };
  },
  methods: {
    async getEmployee() {
      const { data } = await axios.get(`${this.apiUrl}/employees?per_page=`);
      this.teams = data.data;
    },
  },

  beforeMount() {
    this.getEmployee();
  },
};
</script>

<style scoped>
/*===========================
  teams css
===========================*/

.team-area {
  background: transparent !important;
  padding: 90px !important;
}

h1 {
  font-size: 30px !important;
  font-weight: bolder !important;
  color: white;
}

.single-team {
  background: transparent !important;
}

.team-style-one {
  position: relative;
  /* margin-top: 30px; */
  border-radius: 8px;
  overflow: hidden;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}

.carousel__slide--active .team-style-one {
  border-radius: 20px !important;
}

.team-style-one:hover {
  /* box-shadow: var(--shadow-4); */
}

.team-style-one .team-image img {
  /* width: 100%; */
}

.team-style-one .team-content {
  padding: 10px 15px;
  width: 100%;
  background-color: transparent;
}

.carousel__slide--active {
  opacity: 1 !important;
  transform: rotateY(0) scale(1) !important;
}

.team-style-one .team-content .name {
  font-size: 17px;
  font-weight: 600;
  line-height: 30px;
  color: #ffffff;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .team-style-one .team-content .name {
    font-size: 20px;
  }
}

.team-style-one .team-content .sub-title {
  font-size: 15px;
  line-height: 24px;
  color: #bebebe;
  margin-top: 2px;
  display: block;
  text-transform: uppercase;
}

.team-style-one .team-content .social {
  margin-top: 12px;
}

.team-style-one .team-content .social li {
  display: inline-block;
  margin: 0 4px;
}

.team-style-one .team-content .social li a {
  font-size: 16px;
  color: var(--gray-1);
  height: 35px;
  width: 35px;
  text-align: center;
  line-height: 35px;
  border: 1px solid var(--gray-4);
  border-radius: 50%;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}

.team-style-one .team-content .social li a:hover {
  background-color: var(--primary);
  color: var(--white);
  border-color: transparent;
}

.carousel__slide {
  padding: 5px;
}

.carousel__viewport {
  perspective: 2000px;
}

.carousel__track {
  transform-style: preserve-3d;
}

.carousel__slide--sliding {
  transition: 0.5s;
}

.carousel__slide {
  opacity: 0.9;
  transform: rotateY(-20deg) scale(0.9);
}

.carousel__slide--active ~ .carousel__slide {
  transform: rotateY(20deg) scale(0.9);
}

.carousel__slide--prev {
  opacity: 1;
  transform: rotateY(-10deg) scale(0.95);
}

.carousel__slide--next {
  opacity: 1;
  transform: rotateY(10deg) scale(0.95);
}

.carousel__slide--active {
  opacity: 1;
  transform: rotateY(0) scale(1.1);
}

@media only screen and (max-width: 767px) {
  .single-team img {
    height: 250px !important;
    /* margin-right: 0; */
  }
}
</style>
